import React from 'react'
import { Colors, MediaQuery } from '../styles'
import { MdNavigateNext } from 'react-icons/md'
import styled from 'styled-components'

const Container = styled.div`
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  text-align: center;
`

const DollarSign = styled.sup`
  vertical-align: baseline;
  font-size: 1.75rem;
  @media ${MediaQuery.Medium} {
    font-size: 2.75rem;
  }
  @media ${MediaQuery.Large} {
    font-size: 4.5rem;
  }
`

const CentsSign = styled.sup`
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: baseline;
  font-size: 1.75rem;
  @media ${MediaQuery.Medium} {
    font-size: 2.75rem;
  }
  @media ${MediaQuery.Large} {
    font-size: 4.5rem;
  }
`

const Cents = styled.sup`
  vertical-align: baseline;
  font-size: 1.75rem;
  @media ${MediaQuery.Medium} {
    font-size: 2.75rem;
  }
  @media ${MediaQuery.Large} {
    font-size: 4.5rem;
  }
`

const Dollar = styled.span`
  line-height: auto;
  font-size: 3rem;
  @media ${MediaQuery.Medium} {
    font-size: 4.5rem;
  }
  @media ${MediaQuery.Large} {
    font-size: 8rem;
  }
`

const Text = styled.p`
  padding-left: 5px;
  font-size: 0.9rem;
  @media ${MediaQuery.Medium} {
    font-size: 1.1rem;
  }
  @media ${MediaQuery.Large} {
    font-size: 1.25rem;
  }
`

interface Props {
  dollars: number
  cents: number
  trailingText?: string
}

const Price: React.FC<Props> = ({ dollars, cents, trailingText }) => {
  return (
    <Container>
      <div>
        <DollarSign>$</DollarSign>
        <Dollar>{dollars}.</Dollar>
        <Cents>{cents}</Cents>
      </div>

      {trailingText && <Text>{trailingText}</Text>}
    </Container>
  )
}

export const PriceCents: React.FC<Omit<Props, 'dollars'>> = ({
  cents,
  trailingText
}) => {
  return (
    <Container>
      <div>
        {/* <DollarSign>$</DollarSign> */}
        <Dollar>{cents}</Dollar>
        <CentsSign>&#162;</CentsSign>
      </div>

      {trailingText && <Text>{trailingText}</Text>}
    </Container>
  )
}

export default Price
