import React from 'react'
import { Button, Colors, MediaQuery } from '../styles'
import { Link } from 'gatsby'
import styled from 'styled-components'

const LinkStyle = styled.span`
  display: block;
  font-family: arial;
  font-weight: bold;
  color: #fff;
  font-size: 1.25rem;
  text-transform: uppercase;
  padding: 10px 0;

  @media ${MediaQuery.Medium} {
    /* height: 60px; */
    font-size: 1.5rem;
  }
  @media ${MediaQuery.Large} {
    /* height: 80px; */
    /* margin-right: 80px; */
    font-size: 1.5rem;
  }
`

const ButtonPricing: React.FC = (props) => {
  return (
    <Link style={{ width: '100%', textAlign: 'center' }} to="/pricing">
      <LinkStyle>View All Plans</LinkStyle>
    </Link>
  )
}

export default ButtonPricing
