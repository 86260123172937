import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  blueGradientMixin,
  Colors,
  Column,
  H1,
  H2,
  MediaQuery,
  Row,
  Text
} from '../styles'

export type ImageTextCardLayout = 'imageOnLeftSide' | 'imageOnRightSide'

interface Props {
  image?: string
  imageComponent?: React.ReactNode
  bodyComponent: React.ReactNode
  layout: ImageTextCardLayout
  cardBackground?: string
}

const Hero = styled(Row)<Props>`
  padding: 40px 0;
  align-items: center;
  background: ${(p) => p.cardBackground || '#efefef'};
  flex-direction: column;
  flex: 1;
  @media ${MediaQuery.Large} {
    flex-direction: ${(p) =>
      p.layout === 'imageOnLeftSide' ? 'row' : 'row-reverse'};
  }
`

const Image = styled.img`
  width: 90%;
  height: auto;
  @media ${MediaQuery.Medium} {
    width: 80%;
  }
  @media ${MediaQuery.Large} {
    width: 100%;
  }
`

const RightSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const Wrap = styled.div`
  display: flex;
  max-width: 1920px;
  margin: 0 auto;
  flex: 1;
  background: ${Colors.white};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  @media ${MediaQuery.Large} {
    padding: 0 80px;
  }
`

const FullWidthImageTextCard: React.FC<Props> = (props) => {
  const { image, imageComponent, bodyComponent } = props
  return (
    <Wrap>
      <Hero {...props}>
        <LeftSide>
          {imageComponent !== undefined ? (
            imageComponent
          ) : (
            <Image src={image} alt="My school files - signup today!" />
          )}
        </LeftSide>
        <RightSide>
          <Column>
            <Container>{bodyComponent}</Container>
          </Column>
        </RightSide>
      </Hero>
    </Wrap>
  )
}

export default FullWidthImageTextCard
