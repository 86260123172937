import React from 'react'
import { Button } from '../styles'
import { MdNavigateNext } from 'react-icons/md'
import { LoginOrSignup } from '../utils/nav'

const ButtonSignup: React.FC = (props) => {
  return (
    <Button href={'/download'} {...props}>
      INSTALL NOW
      {/* <MdNavigateNext size={40} /> */}
    </Button>
  )
}

export default ButtonSignup
