import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import AppStoreIcon from './AppStoreIcon'
import {
  blueGradientMixin,
  Colors,
  Column,
  FootnoteText,
  H1,
  H2,
  List,
  MediaQuery,
  Row,
  SinglePhoneImage,
  Text,
  UltraWiderPhoneImageWrap
} from '../styles'
import PageContainer from './PageContainer'
import ButtonLearnMore from './ButtonLearnMore'
import { FaHeart } from 'react-icons/fa'
import ButtonSignup from './ButtonSignup'
import ButtonPricing from './ButtonPricing'
import FullWidthImageTextCard from './FullWidthImageTextCard'
import ButtonFreeTrial from './ButtonFreeTrial'
import Price, { PriceCents } from './Price'

const Title = styled(H1)`
  color: #fff;
  text-align: center;
  margin: 10px 5px 0 5px;
  text-transform: none;
  text-align: center;
  font-size: 2.5rem;
  sup {
    /* font-size: 1rem; */
    /* vertical-align: text-top; */
  }
  @media ${MediaQuery.Medium} {
    margin: 20px 0;
    padding: 0 40px;
    font-size: 4rem;
    /* sup {
      font-size: 2rem;
    } */
  }
  @media ${MediaQuery.Large} {
    margin: 30px 0;
    /* text-align: left; */
    font-size: 5rem;
    padding: 0;
    /* sup {
      font-size: 3rem;
    } */
  }
`

const StyledList = styled(List)`
  color: #fff;
`

const CTA = styled(ButtonSignup)`
  background-color: #fff;
  color: ${Colors.blue};
  width: 90%;
  margin: 40px auto;
  @media ${MediaQuery.Small} {
  }
  @media ${MediaQuery.Medium} {
    width: 580px;
    margin: 40px auto;
  }
  @media ${MediaQuery.Large} {
    margin: 40px 0;
  }
`

const CTAPricing = styled(ButtonPricing)`
  /* margin-top: 120px; */
  display: none;
  background-color: #fff;
  color: ${Colors.blue};
  width: 90%;
  /* margin: 40px auto; */
  @media ${MediaQuery.Small} {
  }
  @media ${MediaQuery.Medium} {
    width: 580px;
    /* margin: 40px auto; */
  }
  @media ${MediaQuery.Large} {
    /* margin: 40px 0; */
  }
`

const FreeTrialWrap = styled.div`
  margin: 20px 0;
  text-align: center;
`

interface Props {
  showAllPlansButton?: boolean
}

const PricingHeroCard: React.FC<Props> = ({ showAllPlansButton = true }) => {
  return (
    <FullWidthImageTextCard
      cardBackground={Colors.blue}
      layout="imageOnLeftSide"
      imageComponent={
        <UltraWiderPhoneImageWrap>
          <StaticImage
            style={{ width: '100%', height: '100%' }}
            src="../../static/images/screenshots/ios/pricing.png"
            alt="My School Files"
          />
        </UltraWiderPhoneImageWrap>
      }
      bodyComponent={
        <>
          <Title>
            Starting at <br />
            <PriceCents cents={13} trailingText="per day"></PriceCents>
          </Title>
          <FreeTrialWrap>
            <ButtonFreeTrial />
          </FreeTrialWrap>
          <StyledList>
            <li>All 3 plans give you the exact same features.</li>
            <li>The only difference is how you pay.</li>
            <li>Pay monthly, every 3 months, or once a year.</li>
            <li>Cancel at any time.</li>
          </StyledList>
          <CTA />
          {showAllPlansButton && <CTAPricing />}
        </>
      }
    />
  )
}

export default PricingHeroCard
